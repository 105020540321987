import React from 'react';
import type { FC } from 'react';
import { Card, CardContent, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import { GatsbyImage } from "gatsby-plugin-image";

type ComponentProps = {
  image: any;
  alt: string;
  text: string;
};

export const FlowCard: FC<ComponentProps> = (prop) => {

  const styles = makeStyles((theme: Theme) => createStyles({
    card: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      maxWidth: '250px',
    },
  }))();

  return (
    <Card className={styles.card}>
      <GatsbyImage image={prop.image} alt={prop.alt} />
      <CardContent>
        <Typography>{prop.text}</Typography>
      </CardContent>
    </Card>
  )
};
