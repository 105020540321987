import React from 'react';
import type { FC } from 'react';
import { Container, Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Layout } from '../components/layouts/Layout';
import { FlowCard } from '../components/usage/FlowCard';
import { UsageFlowImagesQuery } from 'types/graphql-types';

const Page: FC = () => {
  const res = useStaticQuery<UsageFlowImagesQuery>(graphql`
    query UsageFlowImages {
      allFile(filter: {relativeDirectory: {eq: "usage_flow"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                height: 250
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
      }
    }`
  );

  const images = res.allFile.edges.map((item) => {
    const fileName = item.node.childImageSharp?.gatsbyImageData.images.fallback.src.split('/').slice(-1)[0];
    return { name: fileName, image: getImage(item.node) };
  });

  const styles = makeStyles((theme) => createStyles({
    container: {
      margin: theme.spacing(3),
      padding: theme.spacing(1),
    },
  }))();


  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>コンビニで手軽にQSLカードを印刷 - QSL Print.com</title>
        <meta name="description" content="ログをアップロードするだけでQSLカードの宛先面がPDFに！コンビニのマルチコピー機や自宅のプリンタで印刷できます。" />
        <meta name="keywords" content="アマチュア無線,QSLカード,宛先,印刷"/>
        <link rel="canonical" href="https://www.qsl-print.com/" />
      </Helmet>

      <Typography variant="h1">QSL Print.com</Typography>

      <Container className={styles.container}>
        <Typography>
          「プリンタが無くてもQSLカードの宛先面を印刷したい」
        </Typography>
        <Typography>
          QSL Print.comはそんな悩みを解決します。
        </Typography>
      </Container>

      <Container className={styles.container}>
        <Typography variant="h4">利用イメージ</Typography>
        <Grid container>
          <FlowCard
            image={ images?.find(i => i.name === "radio_amateur_musen.png")?.image }
            alt="QSOをする"
            text="1. QSOをする"
          />
          <FlowCard
            image={ images?.find(i => i.name === "computer02_man.png")?.image }
            alt="QSL Print.comにQSOログをアップロード"
            text="2. QSL Print.comにQSOログをアップロード"
          />
          <FlowCard
            image={ images?.find(i => i.name === "copy_machine.png")?.image }
            alt="マルチコピー機でQSLカードを印刷"
            text="3. コンビニなどのマルチコピー機でQSLカードを印刷"
          />
          <FlowCard
            image={ images?.find(i => i.name === "post_tegami_toukan.png")?.image }
            alt="QSLカードをビューローに送付"
            text="4. 印刷したQSLカードをビューローに送付"
          />
        </Grid>
      </Container>

      <Container className={styles.container}>
        <Typography variant="h4">サービス開始時期</Typography>
        <Typography>2022年1月中(予定)</Typography>
      </Container>

    </Layout>
  );
}
export default Page;
